import gsap, { Power2 } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import InfiniteScroll from '../_app/cuchillo/scroll/InfiniteScroll';
import InfiniteScroll__Item from '../_app/cuchillo/scroll/InfiniteScroll__Item';
// import { Maths } from '../_app/cuchillo/utils/Maths';
// import { isMobile } from '../_app/cuchillo/core/Basics';

export default class InfiniteScroll__ItemProjectList extends InfiniteScroll__Item {
    wrapper;
    img;
    // opacity = 1;
    // opacityTarget = .25;
    // lerp = .24;

    constructor(__d, __i, __axis = InfiniteScroll.AXIS_Y) {
        super(__d, __i, __axis);

        this.wrapper = GetBy.selector('a', this.dom)[0];
        this.img = GetBy.selector('img', this.dom)[0];
    }

    show() {
        gsap.to(this.wrapper, { y: 0, duration: 1, ease: Power2.easeOut, delay: this.index * .1 });
    }

    hide() {
        gsap.to(this.wrapper, { y: '-110%', duration: .6, ease: Power2.easeOut });
    }

    directHide() {
        gsap.set(this.wrapper, { y: '-110%' });
    }

    // draw() {
    //     super.draw();
    //     if (this.img) this.img.style.opacity = this.opacity;
    // }

    // drawHook() {
    // if (!isMobile) {
    //     const threshold = this.left - (this.width / 2) + this.x;
    //     if (threshold < 0 && threshold > -this.width) this.opacityTarget = 1;
    //     else this.opacityTarget = .25;
    // } else {
    //     const threshold = this.top + this.y - this.height / 2;
    //     if (threshold > 0 && threshold < this.height) this.opacityTarget = 1;
    //     else this.opacityTarget = .25;
    // }

    // this.opacity = Maths.lerp(this.opacity, this.opacityTarget, this.lerp);
    // }
}