import { Object3D, Vector3 } from "three";
import { Maths } from "../utils/Maths";

export default class WebGLGroup extends Object3D {
  rot = new Vector3();
  pos = new Vector3();

  constructor(opts = {}) {
    super();

    const defaults = {
      rotFactor: 1,
      xFactor: 1,
      yFactor: 1,
      zFactor: 1
    }

    this.opts = {
      ...defaults,
      ...opts
    };

    this.hasDirectMove = this.opts.xFactor + this.opts.yFactor + this.opts.zFactor + this.opts.rotFactor === 4;
  }

  update() {
    if (!this.visible) return;

    if (this.hasDirectMove) {
      this.position.x = this.pos.x;
      this.position.y = this.pos.y;
      this.position.z = this.pos.z;

      this.rotation.x = this.rot.x;
      this.rotation.y = this.rot.y;
      this.rotation.z = this.rot.z;
    } else {
      this.position.x = Maths.lerp(this.position.x, this.pos.x, this.opts.xFactor);
      this.position.y = Maths.lerp(this.position.y, this.pos.y, this.opts.yFactor);
      this.position.z = Maths.lerp(this.position.z, this.pos.z, this.opts.zFactor);

      this.rotation.x = Maths.lerp(this.rotation.x, this.rot.x, this.opts.rotFactor);
      this.rotation.y = Maths.lerp(this.rotation.y, this.rot.y, this.opts.rotFactor);
      this.rotation.z = Maths.lerp(this.rotation.z, this.rot.z, this.opts.rotFactor);
    }
  }
}
