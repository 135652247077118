import gsap, { Power3 } from 'gsap';

import _Header from '../_app/cuchillo/layout/Header';
import { GetBy } from '../_app/cuchillo/core/Element';
import EventDispatcher from '../_app/cuchillo/core/EventDispatcher';

export default class Header extends _Header {
  static footer = GetBy.id('Footer');

  static options = {
    show: {
      duration: .8,
      delay: 1,
      ease: Power3.easeOut
    },
    hide: {
      duration: .4,
      delay: .2,
      ease: Power3.easeOut
    }
  }

  static directShow() {
    this.isShow = true;
    gsap.set([this.container, this.footer], { y: 0 });
  }

  static directHide() {
    this.isShow = false;

    gsap.killTweensOf(this.container);
    gsap.killTweensOf(this.footer);
    gsap.set(this.container, { y: '-100%' });
    gsap.set(this.footer, { y: '100%' });
  }

  static show__effect() {
    EventDispatcher.dispatchEvent(_Header.ON_SHOW);

    gsap.to(this.container, {
      y: 0,
      duration: this.options.show.duration,
      delay: this.options.show.delay,
      ease: this.options.show.duration.ease,
      onComplete: () => {
        this.isShowing = false;
      }
    });
  }

  static hide__effect() {
    EventDispatcher.dispatchEvent(_Header.ON_HIDE);
    gsap.to(this.container, {
      y: '-100%',
      duration: this.options.hide.duration,
      delay: this.options.hide.delay,
      ease: this.options.hide.duration.ease,
      onComplete: () => {
        this.isShowing = false;
      }
    });
  }

  static showFooter() {
    gsap.to(this.footer, {
      y: 0,
      duration: this.options.show.duration,
      delay: this.options.show.delay + .1,
      ease: this.options.show.duration.ease
    });
  }

  static hideFooter() {
    gsap.to(this.footer, {
      y: '100%',
      duration: this.options.hide.duration,
      delay: this.options.hide.delay,
      ease: this.options.hide.duration.ease,
      onComplete: () => {
        this.isShowing = false;
      }
    });
  }
}


