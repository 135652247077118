import gsap, { Power3 } from 'gsap';

import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import ProjectsList from '../components/ProjectsList';
import { GetBy } from '../_app/cuchillo/core/Element';
import WebGLSketch from '../_app/cuchillo/3D/WebGLSketch';
import Default from './Default';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Scene from '../3D/Scene';
import Header from '../layout/Header';

export default class NoScroll extends Default {
    productList;

    constructor() {
        super();

        this.productList = new ProjectsList(GetBy.class('__projects-list')[0]);
        document.body.classList.add('__noScroll');
    }

    //SHOW
    afterShow() {
        Scroll.stop();

        let delay = 0;

        if (this.isFirstTime) {
            for (let i = 0; i < this.titles.length; i++) {
                const title = this.titles[i];

                delay += .1;
                gsap.to(title, { x: 0, duration: 2.5, ease: Power3.easeOut, delay });

                delay += .8;
                gsap.to(title, { opacity: 1, duration: .4, ease: Power3.easeOut, delay: .1 * i + .8 });
            }
        }

        if (Scene._initialized) {
            this._isActive = true;
            Scroll.show();
            Header.show();
            Header.showFooter();

            delay -= 3;
        } else {
            delay -= .5;
            Scene.show(delay, () => {
                this._isActive = true;
                Scroll.show();
                Header.show();
                Header.showFooter();
            });
        }

        if (this.isFirstTime) {
            delay += 3;

            for (let i = 0; i < this.titles.length; i++) {
                const title = this.titles[i];
                const direction = i % 2 === 0 ? -1 : 1;
                gsap.to(title, { x: direction * 110 + '%', duration: 1.5, ease: Power3.easeOut, delay });
                gsap.to(title, { opacity: 0, duration: .4, ease: Power3.easeOut, delay: delay + .3 });
            }

            delay -= 3;
        }

        delay += 4;
        gsap.delayedCall(delay, () => {
            this.productList.show();
        });
    }

    //HIDE
    beforeHide() {
        super.beforeHide();

        Header.hideFooter();

        for (let i = 0; i < this.titles.length; i++) {
            const title = this.titles[i];
            gsap.killTweensOf(title)
            const direction = i % 2 === 0 ? -1 : 1;
            gsap.to(title, { x: direction * 110 + '%', duration: 1, ease: Power3.easeOut });
            gsap.to(title, { opacity: 0, duration: .2, delay: .1, ease: Power3.easeOut });
        }

        this.productList.hide();
    }

    afterHide() {
        super.afterHide();

        document.body.classList.remove('__noScroll');
        this.productList.dispose();
    }

    customLoop() {
        Scene.update(this.productList.position, this.productList.speed);
    }

    //RESIZE
    resize() {
        super.resize();
        this.productList.resize();
    }
}

ControllerPage._addPage('no-scroll', NoScroll);