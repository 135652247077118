import { Videos } from '../_app/cuchillo/components/Videos';
import { isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import WebGLSketch from '../_app/cuchillo/3D/WebGLSketch';
import Scene from '../3D/Scene';
import Header from '../layout/Header';

export default class Default extends Page {
  scrollMultiplier = 1;

  constructor() {
    super();

    Videos.init();
  }

  //SHOW
  beforeShow() {
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: this.scrollMultiplier });
    Keyboard.mountPage(this.id);

    if (this.isFirstTime) {
      Header.directHide();
      WebGLSketch.start();
      Scene.start();
    }
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => {
          this.afterShow();
        });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    super.afterShow();

    Scroll.start();
    Scroll.show();

    Header.show();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    Scene.hide();
    super.beforeHide();
  }

  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); }, { delay: .65 });
  }

  afterHide() {
    Scroll.dispose();
    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
  }

  customLoop() {
    Scene.update(Scroll.getPosition(), Scroll.speed);
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();

      this.customLoop();
    }
  }
}

ControllerPage._addPage('default', Default);
