import { isMobile, isSmartphone, isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';
import Header from '../layout/Header';
import Default from './Default';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import gsap from 'gsap';

export default class Project extends Default {
  _call;
  _slider;
  _thumbs;
  scrollMultiplier = isMobile ? 1 : .7;
  _holder;
  _items;
  _images;

  constructor() {
    super();

    this._holder = GetBy.class('__holder', this._container)[0];
    this._thumbs = GetBy.class('__thumb', this._container);
    document.body.classList.add('__noScroll');

    if (!isMobile) {
      this._slider = new SliderScroll(GetBy.class('__slider', this._container)[0], {
        interaction: true,
        hasLimits: false,
        itemClass: SliderScroll__Horizontal__Item
      });
    }

    this._items = GetBy.class('__item', this._container);
    this._images = GetBy.selector('.__item img', this._container);
    gsap.set(this._images, { opacity: 0 });
  }

  afterShow() {
    this._isActive = true;

    Scroll.start();
    Scroll.show();
    Header.show();

    gsap.to(this._images, { opacity: 1, duration: .2, delay: .3 });
  }

  //HIDE
  afterHide() {
    super.afterHide();

    if (this._slider) this._slider.dispose();
    if (isMobile) document.body.classList.remove('__noScroll');
  }

  //RESIZE
  resize() {
    super.resize();
    if (this._slider) this._slider.resize();
  }

  //LOOP
  loop() {
    super.loop();

    let progress = 0;
    let current = 0;

    if (!isMobile && this._slider) {
      progress = Scroll.getProgress();
      this._slider.progress = progress;
      this._slider.loop();

      current = Math.floor(progress * this._thumbs.length);
    } else {
      const { left } = this._items[0].getBoundingClientRect();
      const width = this._holder.offsetWidth * (this._thumbs.length - 1);
      current = Math.floor(Math.abs(left / width) * this._thumbs.length);
    }

    for (let i = 0; i < this._thumbs.length; i++) {
      const thumb = this._thumbs[i];
      if (i <= current) thumb.classList.add('--active');
      else thumb.classList.remove('--active');
    }
  }
}

ControllerPage._addPage('project', Project);

class SliderScroll__Horizontal__Item extends VScroll_Item {
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  mouseOver() { }
  mouseDown() { }
  mouseUp() { }

  show() {
    super.show();
  }

  hide() {
    super.hide();
  }
}