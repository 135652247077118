import WebGLObject from './WebGLObject';
import GeometryController from './GeometryController';
import { SETTINGS } from '../../../3D/constants';
import { Maths } from '../utils/Maths';

export default class SceneGLB extends WebGLObject {
    object;
    // diffusion;
    // normal;
    // roughness;
    // metalness;
    // envMap;

    model;
    // matcap;

    // actions = [];
    // clips = [];
    // animate = false;
    // currentAnimation = -1;
    // animationMixer;

    finalDom;
    isFinal = false;
    factor = 0;

    constructor(opts = {}) {
        super(opts);

        this.model = opts.model;
        this.finalDom = opts.finalDom;
        // this.matcap = opts.matcap;
        // this.diffusion = opts.diffusion;
        // this.normal = opts.normal;
        // this.roughness = opts.roughness;
        // this.metalness = opts.metalness;
        // this.envMap = opts.envMap;

        this.sizeFactor = opts.cols ? opts.cols : SETTINGS.factor;
    }

    init() {
        return new Promise((resolve, reject) => {
            GeometryController.load(
                {
                    src: this.model,
                    call: (gltf) => {
                        this.object = gltf;

                        this.mesh = gltf.scene.children[0];
                        this.add(gltf.scene);

                        // if (this.material) {
                        //     this.mesh.material = this.material;
                        //     this.material.needsUpdate = true;
                        // }

                        // if (this.opts.shadows) {
                        //     gltf.scene.traverse(child => {
                        //         if (child.isMesh) {
                        //             child.castShadow = true;
                        //             child.receiveShadow = true;
                        //         }
                        //     });
                        // }

                        this.size.copy(this.mesh.scale);

                        this.resize();
                        this.loadTextures();

                        resolve();
                    }
                },
                undefined,
                (error) => {
                    reject(error);
                });
        });
    }

    loadAnimations() {
        // // this.animate = true;
        // AnimationController.animationMixer = new AnimationMixer(this.object.scene);

        // const animations = this.object.animations;
        // animations.map(clip => {
        //     console.log(clip, clip.name);
        //     const action = AnimationController.animationMixer.clipAction(clip);
        //     // this.actions.push(action);
        //     AnimationController.registerAnimation(action);
        // });
    }

    loadTextures() {
        // if (this.matcap) {
        //     TexturesController.load({
        //         src: this.matcap,
        //         material: this.material,
        //         attribute: 'matcap'
        //     });
        // }
    }

    update() {
        if (!this.visible) return;

        let dom = this.dom;
        if (this.isFinal) dom = this.finalDom;

        const { x, y, height, width } = dom.getBoundingClientRect();
        const position = this.domPositionTo3D(x, y);

        this.pos.x = (position.x + width * .5);
        this.pos.y = (position.y - height * .5);

        if (!this.mesh) return;

        const factor = width / this.sizeFactor;
        this.factor = Maths.lerp(this.factor, factor, .089);
        this.mesh.scale.set(this.factor * this.size.x, this.factor * this.size.y, this.factor * this.size.z);

        super.update();
    }

    resize(offsetX = 0, offsetY = 0, offsetScale = 1) {
        if (!this.object) return;

        let dom = this.dom;
        if (this.isFinal) dom = this.finalDom;

        const { x, y, width, height } = dom.getBoundingClientRect();

        if (this.hasMove) {
            const position = this.domPositionTo3D(x, y);
            this.position.x = (position.x + width * .5) - offsetX;
            this.position.y = (position.y - height * .5) - offsetY;
            this.pos.x = this.position.x;
            this.pos.y = this.position.y;
        } else {
            this.position.x = 0;
            this.position.y = 0;
            this.pos.x = 0;
            this.pos.y = 0;
        }

        this.factor = width / this.sizeFactor;
        this.mesh.scale.set((this.factor * this.size.x) / offsetScale, (this.factor * this.size.y) / offsetScale, (this.factor * this.size.z) / offsetScale);
    }
}
