import gsap, { Power3 } from 'gsap';

import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Header from '../layout/Header';
import Default from './Default';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Scene from '../3D/Scene';

export default class NotFound extends Default {
    constructor() {
        super();

        document.body.classList.add('__noScroll');
    }

    beforeShow() {
        super.beforeShow();
        Scene.start();
    }

    //SHOW
    afterShow() {
        Scroll.stop();

        let delay = 0;

        if (this.isFirstTime) {
            for (let i = 0; i < this.titles.length; i++) {
                const title = this.titles[i];

                delay += .1;
                gsap.to(title, { x: 0, duration: 2.5, ease: Power3.easeOut, delay });

                delay += .8;
                gsap.to(title, { opacity: 1, duration: .4, ease: Power3.easeOut, delay: .1 * i + .8 });
            }
        }

        Scene.show(delay, () => {
            this._isActive = true;
            Scroll.show();
            Header.directHide();
        });

        if (this.isFirstTime) {
            delay += 2;

            for (let i = 0; i < this.titles.length; i++) {
                const title = this.titles[i];
                const direction = i % 2 === 0 ? -1 : 1;
                gsap.to(title, { x: direction * 110 + '%', duration: 1.5, ease: Power3.easeOut, delay });
                gsap.to(title, { opacity: 0, duration: .4, ease: Power3.easeOut, delay: delay + .3 });
            }

            delay -= 2;
        }
    }

    //HIDE
    beforeHide() {
        super.beforeHide();

        for (let i = 0; i < this.titles.length; i++) {
            const title = this.titles[i];
            gsap.killTweensOf(title)
            const direction = i % 2 === 0 ? -1 : 1;
            gsap.to(title, { x: direction * 110 + '%', duration: 1, ease: Power3.easeOut });
            gsap.to(title, { opacity: 0, duration: .2, delay: .1, ease: Power3.easeOut });
        }
    }

    afterHide() {
        super.afterHide();
        document.body.classList.remove('__noScroll');
    }
}

ControllerPage._addPage('not-found', NotFound);