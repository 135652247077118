import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap } from "gsap";
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';

class ScrollItem__SliderFullScreen extends VScroll_Item {
    _call;
    _slider;
    _thumbs;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._thumbs = GetBy.class('__thumb', __link);

        this._slider = new SliderScroll(__link, {
            interaction: true,
            hasScrollbar: false,
            itemClass: ScrollItem__SliderFullScreen__Item
        });

        this._call = () => this.loop();
        this.onShow = () => {
            console.log('SHOW');

            gsap.ticker.add(this._call);
        };

        this.onHide = () => {
            console.log('HIDE');
            this.dispose();
            gsap.ticker.remove(this._call);
        };
    }

    resize(w, h) {
        super.resize(w, h);
        if (this._slider) this._slider.resize();
    }

    loop() {
        if (this._slider) {
            this._slider.loop();

            for (let i = 0; i < this._thumbs.length; i++) {
                const thumb = this._thumbs[i];
                if (i <= this._slider.actual) thumb.classList.add('--active');
                else thumb.classList.remove('--active');
            }
        }
    }

    dispose() {
        if (this._slider) this._slider.dispose();
        super.dispose();
    }
}

class ScrollItem__SliderFullScreen__Item extends VScroll_Item {
    // hasHiddenEnabled = false;
    // info;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    //update () {
    /* if (this.progress > .5) {
       const p = Math.max(0, Maths.normalize(1, .5, this.progress));
       this._x = this._x + ((Metrics.WIDTH * .5) * p);
     }*/
    /* super.update();
  }

  mouseOver () { }
  mouseDown () { }
  mouseUp () { }

  show () {
    super.show();
  }

  hide () {
    super.hide();
  }*/
}

Scroll._registerClass('SliderFullScreen', ScrollItem__SliderFullScreen);
