import gsap from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import InfiniteScroll__ItemProductsList from '../scroll/InfiniteScroll__ItemProjectList';
import InfiniteScroll from '../_app/cuchillo/scroll/InfiniteScroll';
import { isMobile } from '../_app/cuchillo/core/Basics';
import { Metrics } from '../_app/cuchillo/core/Metrics';

export default class ProjectsList {
    container;
    scrollers = [];
    holders = [];
    mainholder;

    get position() {
        return this.scrollers[0].position;
    }

    get speed() {
        return this.scrollers[0].speed;
    }

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__container) {
        this.container = __container;

        const axis = isMobile ? InfiniteScroll.AXIS_Y : InfiniteScroll.AXIS_X;

        this.holders = GetBy.class('__holder', this.container);
        for (let i = 0; i < this.holders.length; i++) {
            const holder = this.holders[i];
            const scroller = new InfiniteScroll(axis, {
                domResize: this.container,
                appendContainer: holder,
                container: holder,
                multiplicator: 1,
                gap: isMobile ? 10 : 20,
                itemClass: InfiniteScroll__ItemProductsList
            });
            this.scrollers.push(scroller);
        }

        this._call = () => this.loop();
    }

    show() {
        gsap.ticker.add(this._call);

        for (let i = 0; i < this.scrollers.length; i++) {
            const scroller = this.scrollers[i];
            scroller.start();
            scroller.show();
        }
    }

    hide() {
        for (let i = 0, j = this.scrollers.length; i < j; i++) {
            const scroller = this.scrollers[i];
            scroller.hide();
        }
    }

    loop() {
        for (let i = 0, j = this.scrollers.length; i < j; i++) {
            const scroller = this.scrollers[i];
            scroller.loop();
        }
    }

    dispose() {
        gsap.ticker.remove(this._call);

        for (let i = 0, j = this.scrollers.length; i < j; i++) {
            const scroller = this.scrollers[i];
            scroller.dispose();
        }
    }

    resize() {
        for (let i = 0, j = this.scrollers.length; i < j; i++) {
            const scroller = this.scrollers[i];
            scroller.resize();
        }
    }
}